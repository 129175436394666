<template>
  <div class="content">
    <div class="changeInfo">
      <div class="changeTitle">选择舱位</div>
      <div class="tickedTimeDetail">
        <div class="AviationInfo">
          <p><span>{{ rtFlagText }}</span></p>
          <img :src="ticketInfo.logo" alt="" style="margin-left: 17px;height: 17px;">
          <p>{{ ticketInfo.airCompanyName }}{{ ticketInfo.flightNo }}</p>
          <p>{{ ticketInfo.date }}</p>
          <p>{{ ticketInfo.depTimeW }}</p>
        </div>
        <div class="TimeInfo">
          <div class="stareTimeInfo">
            <p>{{ ticketInfo.depTime }}</p>
            <p>{{ ticketInfo.startCity }}{{ ticketInfo.depAirportName }}</p>
          </div>
          <div class="TimeImg">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/21/yuelvhuiLoDKVslO4A1609163787.png"
              alt=""
            >
          </div>
          <div class="endTimeInfo">
            <p>{{ ticketInfo.arrTime }}</p>
            <p>{{ ticketInfo.endCity }}{{ ticketInfo.arrAirportName }}</p>
          </div>
        </div>
      </div>
      <div class="changeDetail">
        <div class="changePriceWrap">
          <p>改签</p>
          <p>¥<span>{{ changePrice }}</span></p>
        </div>
        <div class="changeTipsWrap">
          <div class="tipsInfo">
            <p class="">{{ changeAccommodationType }}{{ Discount }}折</p>
            <p class="" />
            <div class="">
              <p class="" />
              <img src="" alt="">
            </div>
          </div>
          <img class="Launching" src="" alt="">
        </div>
      </div>
      <div class="changeInfo" />
    </div>
    <div class="changeConfirmation">
      <p>确定</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangeChoiceAcc',
  data() {
    return {
      rtFlagText: '', // 行程类型
      ticketInfo: {
        logo: '',
        airCompanyName: '',
        flightNo: '',
        date: '',
        depTimeW: '',
        depTime: '',
        startCity: '',
        depAirportName: '',
        arrTime: '',
        endCity: '',
        arrAirportName: ''
      }, // 机票详情
      changePrice: 0, // 改签金额
      changeAccommodationType: '', // 舱位类型
      Discount: ''// 费用折扣

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {}
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  font-family: 'PingFang SC Medium';
}

.content {
  position: absolute;

  .changeInfo {
    .changeTitle {
      width: 100%;
      height: 44px;
      line-height: 44px;
      text-align: center;
      font-size: 17px;
      color: #333333;
      background-color: #fff;
    }

    /*行程详情*/

    .tickedTimeDetail {
      padding: 31px 23px;
      background-color: #fff;
      /*margin-top: 43px;*/

      .AviationInfo {
        display: flex;

        p {
          line-height: 17px;
          font-size: 13px;
          color: #333333;
        }

        p:nth-child(1) {
          width: 32px;
          height: 17px;
          text-align: center;
          background-color: #E33F44;
          color: #ffffff;
          border-radius: 3px;

          span {
            display: inline-block;
            transform: scale(0.8);
          }
        }

        p:nth-child(2) {
          margin-left: 28px;
        }

        p:nth-child(3) {
          margin-left: 10px;
        }

        p:nth-child(4) {
          margin-left: 10px;
        }
      }

      .TimeInfo {
        display: flex;
        margin-top: 20px;

        .stareTimeInfo, .endTimeInfo {
          color: #333333;
          flex: 1;

          p:nth-child(1) {
            font-size: 29px;
            font-weight: 600;
          }

          p:nth-child(2) {
            font-size: 12px;
          }
        }

        .TimeImg {
          flex: 1;

          img {
            width: 52px;
            height: 6px;
            margin: 14px 28px;
          }
        }
      }
    }

    /*改签详情查退改*/

    .changeDetail {
      .changePriceWrap {
        display: flex;
        padding: 16px 13px;

        p:nth-child(1) {
          font-size: 17px;
          color: #333333;
          font-weight: 600;
        }

        p:nth-child(2) {
          margin-left: auto;
          font-size: 13px;
          color: #E33F44;

          span {
            display: inline-block;
            margin-left: 5px;
            font-size: 17px;
          }
        }
      }

      .changeTipsWrap {
        display: flex;
        padding: 16px 13px;

        .Launching {
          margin-left: auto;
        }
      }
    }

    /*改签手续费*/

  }

  .changeConfirmation {
    position: absolute;
    bottom: 59px;
    padding: 13px;
    width: 100%;

    p {
      width: 100%;
      height: 45px;
      text-align: center;
      line-height: 45px;
      background-color: #E33F44;
      border-radius: 7px;
      font-size: 17px;
      color: #ffffff;
    }
  }
}
</style>
